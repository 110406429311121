.containerSelectHeader {
  min-width: 170px !important;
  display: flex;
}
.containerSelect {
  min-width: 170px;
}

.containerMonth {
  width: 160px;
}

.containerRelative {
  position: relative;
  z-index: 1;
}

.pieContainer {
  width: 45%;
  background: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
}

.pieContainer p {
  font-weight: 700;
}

.lineContainer {
  background-color: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
  width: 95%;
}

.barContainer {
  background-color: #fcfcfc;
  padding: 8px;
  border-radius: 20px;
}

.customCard {
  position: absolute;
  top: 50px;
  background-color: #fcfcfc;
  padding: 24px 16px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.customCard p {
  font-size: 20px;
  margin-bottom: 16px;
}

.customCard span {
  font-weight: 700;
}
