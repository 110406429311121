@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;700&display=swap");

.messages {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-top: 2vh;
  font-family: "Source Sans Pro";
  font-size: 1.5vw;
  margin-top: -1vh;
}
.containerVertical {
  border: 1px solid #d7d7d7;
  width: 100%;
}

.containerInput {
  max-width: 98%;
}

.containerTextArea {
  max-width: 95%;
}

.new-btn-save-message {
  padding: 20px;
  max-height: 100px !important;
  max-width: 350px !important;
}

.margin-message-bottom {
  margin-bottom: 16px;
}
.margin-label-bottom {
  margin-bottom: 4px;
}

.rightToolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.msgToolBar {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-between;
  align-items: center;
}

.messages .slBottomBox {
  margin-top: 2vh;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  border-radius: 0px 0px 7px 7px;
  color: white;
}

.modalCall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50vh;
  width: 100%;
}

.modalCall form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50vh;
  /* width: 50%; */
}

.midiaZone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3vh;
  margin-bottom: 9vh;
}

.midia__upload__queue {
  margin-bottom: 2vh;
}

.brokerChoose {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 10vh;
  width: 23vw;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.brokerBoxfalse {
  background-color: #297fb956;
  border-radius: 7px;
  width: 10vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.brokerBoxtrue {
  background-color: #2980b9;
  border-radius: 7px;
  width: 10vw;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.brokerBoxfalse:hover {
  background-color: #2980b9;
  transition: all 0.5s;
  cursor: pointer;
}

.brokerBoxtrue:hover {
  cursor: pointer;
}

.brokerBoxfalse img {
  padding-left: 0.5vw;
  height: 5vh;
  width: 9vw;
}

.brokerBoxtrue img {
  padding-left: 0.5vw;
  height: 5vh;
  width: 9vw;
}

.templateAlign {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.codeMessage {
  height: 10vh;
  width: 100%;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bodyMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parameters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .parameters input {
  width: calc(12px + 27vw);
  height: 5vh;
  max-height: 60px;
  max-width: 600px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  box-sizing: border-box;
  border-radius: 6px 0px 0px 6px;
  margin-top: 1vh;
  margin-bottom: 4vh;
} */

.parameters .inputParameterAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.paramAddBtn {
  display: flex;
  width: 3vw;
  height: 40px;
  max-width: 60px;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
  transition: filter 1s;
}

.paramAddBtn:hover {
  filter: brightness(80%);
  /* display: flex;
  width: 3vw;
  height: 5vh;
  max-height: 60px;
  max-width: 60px;
  justify-content: center;
  align-items: center;
  background-color: #127561;
  border-radius: 0px 6px 6px 0px;
  margin-bottom: 3vh;
  cursor: pointer;
  transition: all 0.5s; */
}

.cleanBtn {
  display: flex;
  width: 3vw;
  height: 5vh;
  max-height: 60px;
  max-width: 60px;
  justify-content: center;
  align-items: center;
  background-color: #e74c3c;
  color: white;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
  margin-top: 3vh;
}

.cleanBtn:hover {
  display: flex;
  width: 3vw;
  height: 5vh;
  max-height: 60px;
  max-width: 60px;
  justify-content: center;
  align-items: center;
  background-color: #a13529;
  color: white;
  border-radius: 0px 6px 6px 0px;
  cursor: pointer;
  margin-top: 3vh;
}

.title {
  font-size: calc(12px + 1.2vw);
}

.midiaZone .inputMidiaAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2vh;
}

.midiaZone .uploadInput {
  width: calc(12px + 22vw);
  height: 5vh;
  max-height: 50px;
  max-width: 420px;

  background: rgba(196, 196, 196, 0.1);
  border: 1px solid rgba(41, 128, 185, 0.25);
  margin-top: 5vh;
  margin-bottom: 2vh;
  box-sizing: border-box;
  font-size: calc(8px + 0.5vw);
}

.paramTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 7vh;
  width: calc(12px + 30vw);
  font-size: calc(8px + 0.8vw);
  max-width: 550px;
}

.paramTable .paramAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.paramTable .btnDisable {
  border: none;
  background-color: #828282;
  margin: 5px;
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 8px;
  cursor: not-allowed;
}

.paramTable .btnEnable {
  border: none;
  background-color: #2bff00;
  margin: 5px;
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 8px;
}

.paramTable .btnDel {
  border: none;
  background-color: #ff1900;
  margin: 5px;
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 8px;
}

/* Dropzone Media */

.midia__upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
  max-width: 600px;
}

.midia__upload__wrapper {
  margin-bottom: 3vh;
}

.midia__upload__message__dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 50vh;
  max-height: 500px;
  max-width: 600px;
  border-style: dashed;
  background-color: #e6e6e6;
  border-width: 2;
  border-radius: 2;
  border-color: #ababab;
  text-align: center;
}

.midia__upload__ghistory__dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 20vh;
  max-height: 500px;
  max-width: 500px;
  border-style: dashed;
  background-color: #e6e6e6;
  border-color: #ababab;
  text-align: center;
  cursor: pointer;
}

.inline_Alt {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 8vw;
}

.brokerStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.brokerStyle img {
  width: 4vw;
  height: 3.25vh;
}

.typeChoose {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 10vh;
  width: 23vw;
  margin-top: 1vh;
  margin-bottom: 2vh;
}

.typeChoose input[type="radio"] {
  visibility: hidden;
  position: absolute;
  width: 1em;
  height: 1em;
}

.typeBoxfalse {
  background-color: #297fb956;
  border-radius: 7px;
  width: 10vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.3vw;
}

.typeBoxtrue {
  background-color: #2980b9;
  border-radius: 7px;
  width: 10vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.3vw;
}

.typeBoxfalse:hover {
  background-color: #2980b9;
  transition: all 0.5s;
  cursor: pointer;
}

.typeBoxtrue:hover {
  cursor: pointer;
}

/* ------------------------------------------- */

.addTab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
}

.selectorTab {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-size: 0.8vw;
  margin-right: 1vh;
  border-radius: 7px;
  height: 4.6vh;
  width: 25vw;
  margin-right: 2vh;
  padding-right: 10px;
  padding-left: 10px;
  transition: ease-out 0.5s;
  margin-bottom: 2vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.addTab span {
  font-size: 1vw;
}

.midiaSendBtn {
  width: calc(12px + 15vw);
  height: calc(6px + 8vw);
  font-size: calc(12px + 1.8vw);
  max-width: 250px;
  max-height: 75px;
  background-color: #1abc9c;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: all 0.7s;
}

.midiaSendedBtn {
  width: calc(12px + 15vw);
  height: calc(6px + 8vw);
  font-size: calc(12px + 1.8vw);
  background-color: #1abc9c;
  color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #95a5a6;
}

.midiaSendBtn:hover {
  background-color: #0f6c5a;
}

.titleCentering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ------------------------------------------- */

@media only screen and (max-width: 1300px) {
  .messages {
    display: flex;
    flex-direction: column;
    padding-left: 10vw;
    padding-top: 3vh;
    font-weight: 300;
    font-family: "Source Sans Pro";
    font-size: 1.5vw;
    margin-top: unset !important;
  }
}
